@tailwind base;
@tailwind components;
@tailwind utilities;

@font-face {
  font-family: Bold;
  src: url('./fonts/CopperplateGothicBold.ttf') format('truetype');
}

@font-face {
  font-family: 'Light';
  src: url('./fonts/CopperplateGothicLight.ttf') format('truetype');
}

@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@400;500;600;700&display=swap');

body {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: 'Montserrat', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow-x: hidden;
  background: #F5EEE6;
}

.hideMenuNav {
  display: none;
}
.showMenuNav {
  display: block;
  position: absolute;
  width: 100%;
  height: 100vh;
  top: 0;
  left: 0;
  background: white;
  z-index: 10;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center;
}

.card1 {
  clip-path: polygon(-10% 0, 85% 0, 100% 100%, -10% 100%);
}

.card2 {
  clip-path: polygon(15% 0, 110% 0, 110% 100%, 0% 100%);
}